import { FunnelId } from 'constants/funnel';
import Button from 'components/Qds/Button';
import ExternalFunnelButton from 'components/Shared/ExternalFunnel/ExternalFunnelButton';
import Icon from 'components/ui/Icon';
import Image from 'components/ui/Image';
import Link from 'components/ui/Link';
import config from 'config';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useAnalytics } from 'hooks/useAnalytics';
import { useBrand } from 'hooks/useBrand';
import { useCountry } from 'hooks/useCountry';
import { useTranslation } from 'hooks/useTranslation';
import type { Country } from 'types/Utilities';

type Props = {
  verticalForm?: boolean;
};

const GetInTouch = ({ verticalForm }: Props): JSX.Element => {
  const { trackEvent } = useAnalytics();
  const { brand } = useBrand();
  const { t } = useTranslation();

  const { defaultManager } = brand || {};
  const { countries } = config;
  const { email, whatsApp } = config.defaults;

  const [currentCountry, setCurrentCountry] = useState(
    config.defaults.country as keyof typeof countries,
  );

  useEffect(() => {
    const { country } = useCountry();
    setCurrentCountry(country as keyof typeof countries);
  }, []);

  return (
    <div
      className={clsx(
        'bg-secondary-bright',
        !verticalForm ? ' px-4 lg:px-0' : 'md:bg-transparent',
      )}
    >
      <div
        className={clsx(
          'max-w-screen-xl mx-auto pb-12 md:py-20',
          !verticalForm && 'px-3 md:px-4',
        )}
      >
        <div
          className={clsx(
            !verticalForm
              ? 'md:grid md:grid-cols-3 gap-12 lg:gap-32'
              : 'md:rounded-t md:shadow-lg md:border',
          )}
        >
          {!defaultManager ? (
            <div />
          ) : (
            <div className="md:-mt-32">
              <div
                className={clsx(
                  'h-48 md:h-64 bg-no-repeat bg-cover bg-center py-4 px-12 relative overflow-hidden',
                  !verticalForm ? 'md:rounded' : 'rounded-lg',
                )}
              >
                <Image
                  src={defaultManager.profileImage.url || ''}
                  alt={defaultManager.firstname || ''}
                  fill
                  priority
                  className="object-cover"
                />
                <div className="relative">
                  <div className="hidden md:inline bg-secondary rounded py-1 px-4 absolute right-0">
                    {t('home.getInTouch.hello')}!
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  'md:block bg-white p-6 rounded border relative',
                  verticalForm &&
                    'md:rounded-none md:border-none shadow-md md:shadow-none -mt-8',
                )}
              >
                <p className="uppercase font-bold text-2xs">
                  {t('home.getInTouch.patientManager')}
                </p>
                <p
                  className={clsx(
                    'text-navy font-netto text-2xl',
                    !verticalForm && 'mb-5',
                  )}
                >
                  {defaultManager.firstname || ''}
                </p>
                <p className="text-sm">
                  {t('home.yourPersonalPatientsCareManager')}
                </p>
                <ExternalFunnelButton
                  funnelId={FunnelId.CONTACT_FORM}
                  renderButton={(openFunnel) => {
                    return (
                      <Button
                        className="mt-4"
                        fullWidth
                        title={t('home.askAQuestion')}
                        onClick={() => {
                          trackEvent({
                            category: 'CTA',
                            action: 'CTA|Click',
                            label: `CTA|Contact|Justyna|AskaQuestion`,
                          });

                          openFunnel();
                        }}
                      >
                        {t('home.askAQuestion')}
                      </Button>
                    );
                  }}
                />
              </div>
            </div>
          )}
          <div
            className={clsx(
              'mb-8 md:my-0',
              verticalForm && 'mx-5 sm:mx-0 md:hidden',
            )}
          >
            <h2 className="md:pt-0">{t('home.getInTouch.letsTalk')}</h2>
            <p className="text-base md:text-lg">{t('home.getInTouch.text')}</p>
          </div>
          <div className={clsx(verticalForm && 'p-6')}>
            <Link href={`tel:${countries[currentCountry].phone}`}>
              <a
                className="flex w-full items-center p-3 bg-white border justify-center border-grey-darkest text-primary rounded mb-4 relative hover:text-white hover:bg-navy transition-colors duration-200 ease-in-out"
                target="_self"
                onClick={() => {
                  trackEvent({
                    category: 'CTA',
                    action: 'CTA|Click',
                    label: `CTA|Contact|Justyna Let's Talk|Phone`,
                  });
                }}
              >
                <Icon
                  type="PhoneLines"
                  height="21px"
                  color="#fff"
                  className="ml-1 fill-current"
                />
                <span className="ml-3 text-3xs">
                  {countries[currentCountry].phone}
                </span>
              </a>
            </Link>
            <Link href={`mailto:${email}`}>
              <a
                title="Email"
                target="_blank"
                rel="noopener nofollow noreferrer"
                className="py-3 px-3 bg-white text-primary border justify-center border-grey-darkest rounded relative mb-4 uppercase flex items-center hover:text-white hover:bg-navy transition-colors duration-200 ease-in-out"
                onClick={() => {
                  trackEvent({
                    category: 'CTA',
                    action: 'CTA|Click',
                    label: `CTA|Contact|Justyna Let's Talk|Email`,
                  });
                }}
              >
                <span className="text-3xs">{email}</span>
              </a>
            </Link>
            <Link
              href={`https://api.whatsapp.com/send?phone=${
                (countries[currentCountry] as Country).whatsApp || whatsApp
              }`}
            >
              <a
                title="Whatsapp"
                target="_blank"
                rel="noopener nofollow noreferrer"
                className="mb-4 py-3 px-3 bg bg-white text-primary border justify-center border-grey-darkest rounded relative uppercase text-3xs flex items-center hover:text-white hover:bg-navy transition-colors duration-200 ease-in-out"
                onClick={() => {
                  trackEvent({
                    category: 'CTA',
                    action: 'CTA|Click',
                    label: `CTA|Contact|Justyna Let's Talk|WhatsApp`,
                  });
                }}
              >
                <span className="text-3xs">{t('whatsapp')}</span>
              </a>
            </Link>
            <Link href="https://www.messenger.com/t/qunomedical/">
              <a
                title="Whatsapp"
                target="_blank"
                rel="noopener nofollow noreferrer"
                className="py-3 px-3 bg bg-white text-primary border justify-center border-grey-darkest rounded relative uppercase text-3xs flex items-center hover:text-white hover:bg-navy transition-colors duration-200 ease-in-out"
                onClick={() => {
                  trackEvent({
                    category: 'CTA',
                    action: 'CTA|Click',
                    label: `CTA|Contact|Justyna Let's Talk|Facebook`,
                  });
                }}
              >
                <span className="text-3xs">Facebook</span>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
