import { useTranslation } from 'hooks/useTranslation';
import Script from 'next/script';
import { useContext, useEffect, useState } from 'react';
import { createUniqueId } from 'utils/createUniqueId';
import { FunnelContext } from 'context/funnel';
import type { FC } from 'react';
import type { ExternalFunnelConfig } from './ExternalFunnelConfig';

export type ExternalFunnelProps = Omit<ExternalFunnelConfig, 'locale'>;

type Props = ExternalFunnelProps & {
  preload?: boolean;
  onScriptLoaded?: () => void;
};

const ExternalFunnel: FC<Props> = ({
  preload,
  onScriptLoaded,
  ...externalFunnelConfig
}) => {
  const { locale } = useTranslation();

  const { externalFunnelLoaded, setExternalFunnelLoaded } =
    useContext(FunnelContext);

  const [funnelContainerId, setFunnelContainerId] =
    // null is not a valid id
    useState<string | undefined>();

  useEffect(() => {
    // useEffect is needed to prevent a warning saying that the id is different
    // between client and server
    setFunnelContainerId(createUniqueId());
  }, []);

  useEffect(() => {
    if (externalFunnelLoaded) {
      onScriptLoaded?.();
    }
  }, [externalFunnelLoaded]);

  useEffect(() => {
    if (!externalFunnelLoaded || !funnelContainerId) {
      return;
    }

    if (preload) {
      void window.loadExternalFunnel(externalFunnelConfig.funnelId, locale);
    } else {
      void window.registerExternalFunnel(
        {
          ...externalFunnelConfig,
          locale,
        },
        funnelContainerId,
      );
    }
  }, [
    externalFunnelLoaded,
    funnelContainerId,
    preload,
    externalFunnelConfig,
    locale,
  ]);

  return (
    <>
      {!preload && <div id={funnelContainerId} className="external-funnel" />}
      <Script
        id="external-funnel"
        type="module"
        src="/funnel/funnel-v12.js"
        onLoad={() => {
          setExternalFunnelLoaded(true);
        }}
      />
    </>
  );
};

export default ExternalFunnel;
