import { STATIC_PATH_FALLBACK } from 'constants/environment';
import { FunnelId } from 'constants/funnel';
import { CLOUDINARY_UPLOAD_URL, IMGS_PATH, INVESTORS } from 'constants/image';
import {
  Hero,
  SearchSection,
  Slider,
  SpecialitiesOverview,
  StructuredData,
} from 'components/Pages/Home';
import Button from 'components/Qds/Button';
import ConversionBar from 'components/Shared/ConversionBar';
import GetInTouch from 'components/Shared/GetInTouch';
import PreviouslyFeaturedOn from 'components/Shared/PreviouslyFeaturedOn';
import TrustBanner from 'components/Shared/TrustBanner';
import ExternalFunnelButton from 'components/Shared/ExternalFunnel/ExternalFunnelButton';
import Icon from 'components/ui/Icon';
import Image from 'components/ui/Image';
import Layout from 'components/ui/Layout';
import Link from 'components/ui/Link';
import TrustBox from 'components/ui/TrustBox';
import config from 'config';
import { getMainFooter, skipServerSideBuild } from 'data/page/utils';
import { getBrand } from 'data/page/utils/getBrand';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'hooks/useTranslation';
import { useEffect, useState } from 'react';
import tailwind from 'utils/breakpoints';
import { getAverageRating, getAllReviewsForCount } from 'data/utils/reviews';
import type { GetStaticPaths, GetStaticProps } from 'next';
import type { AlternateSlug } from 'types/AlternateSlugs';
import type { Brand } from 'types/Brand';
import type { FooterItemType } from 'types/Footer';
import type { LeadForm } from 'types/LeadForm';
import type { ConfigLocale } from 'types/PageData';

type Props = {
  alternateSlugs: AlternateSlug[];
  footer: FooterItemType | null;
  reviewStats: { avg_rating: number; total: number | undefined } | null;
  brand: Brand | null;
};

const Home = ({
  alternateSlugs,
  footer,
  reviewStats,
  brand,
}: Props): JSX.Element => {
  const { locale, t } = useTranslation();
  const [data, setData] = useState<Partial<LeadForm> | null>(null);
  const { trackEvent } = useAnalytics();

  const popularTreatments = [
    {
      title: ['nl', 'es', 'it', 'fr'].includes(locale)
        ? 'hairTransplant'
        : 'fueHairTransplant',
      price: 1850,
      rating: 4.8,
      reviews: 809,
      image: `${IMGS_PATH.PUBLIC}/fue-hairtransplants.jpg`,
      speciality: {
        en: 'plastic-surgery',
        de: 'plastische-chirurgie',
        nl: 'plastische-chirurgie',
        es: 'cirugia-plastica',
        it: 'chirurgia-plastica',
        fr: 'chirurgie-esthetique',
      },
      treatment: {
        en: 'fue-hairtransplant',
        de: 'fue-haartransplantation',
        nl: 'haartransplantatie',
        es: 'injerto-capilar',
        it: 'trapianto-di-capelli',
        fr: 'greffe-de-cheveux',
      },
      show: ['de', 'en', 'nl', 'es', 'it', 'fr'],
    },
    {
      title: 'veneers',
      price: 195,
      rating: 4.9,
      reviews: 92,
      image: `${IMGS_PATH.PUBLIC}/veneers.jpg`,
      speciality: {
        en: 'dentistry',
        de: 'zahnmedizin',
        nl: 'tandheelkunde',
        es: 'tratamientos-dentales',
        it: 'odontoiatria',
        fr: 'dentisterie',
      },
      treatment: {
        en: 'veneers',
        de: 'veneers',
        nl: 'facings',
        es: 'carillas-dentales',
        it: 'faccette-dentali',
        fr: 'facettes-dentaires',
      },
      show: ['de', 'en', 'nl', 'es', 'it', 'fr'],
    },
    {
      title: 'rhinoplasty',
      price: 2380,
      rating: 4.9,
      reviews: 41,
      image: `${IMGS_PATH.PUBLIC}/rhinoplasty.jpg`,
      speciality: {
        en: 'plastic-surgery',
        de: 'plastische-chirurgie',
        nl: 'plastische-chirurgie',
        es: 'cirugia-plastica',
        it: 'chirurgia-plastica',
        fr: 'chirurgie-esthetique',
      },
      treatment: {
        en: 'rhinoplasty',
        de: 'nasenkorrektur',
        nl: 'neuscorrectie',
        es: 'rinoplastia',
        it: 'rinoplastica',
        fr: 'rhinoplastie',
      },
      show: ['de', 'en', 'nl', 'es', 'it', 'fr'],
    },
    {
      title: 'inVitoFertilization',
      price: 2800,
      rating: 5.0,
      reviews: 12,
      image: `${IMGS_PATH.PUBLIC}/in-vitro-fertilization.jpg`,
      speciality: {
        en: 'reproductive-medicine',
        de: 'reproduktionsmedizin',
      },
      treatment: {
        en: 'invitro-fertilization',
        de: 'in-vitro-fertilisation ',
      },
      show: ['de', 'en'],
    },
    {
      title: 'breastaug',
      price: 2921,
      rating: 5.0,
      reviews: 29,
      image: `${IMGS_PATH.PUBLIC}/plastic-surgery-breast.jpg`,
      speciality: {
        en: 'reproductive-medicine',
        de: 'reproduktionsmedizin',
        nl: 'plastische-chirurgie',
        es: 'cirugia-plastica',
        it: 'chirurgia-plastica',
        fr: 'chirurgie-esthetique',
      },
      treatment: {
        en: 'invitro-fertilization',
        de: 'in-vitro-fertilisation ',
        nl: 'borstvergroting',
        es: 'aumento-de-pecho',
        it: 'mastoplastica-additiva',
        fr: 'augmentation-mammaire',
      },
      show: ['nl', 'es', 'it', 'fr'],
    },
  ];

  useEffect(() => {
    if (!data && ['nl', 'es', 'it', 'fr'].includes(locale)) {
      setData({
        treatment: t('speciality.hairTransplant'),
        skipValidation: true,
      });
    }
  }, []);

  const popularDoctors = [
    {
      name: {
        de: 'Dr. Ziya Yavuz',
        other: 'Dr. Ziya Yavuz, MD',
      },
      speciality: 'hairTransplant',
      slug: 'dr-ziya-yavuz-md',
      location: `Dr. Ziya Yavuz Hair Transplant Clinic, Istanbul, ${t(
        'countries.turkey',
      )}`,
      image: `${IMGS_PATH.CONTENTFUL}/1dEhsXqydfnrO3Eyz3MEaJ/545e813d944cef26e0faf97196ff605f/drzy.jpg`,
      qunoscore: 9.2,
      treatmentsPerformedLastYear: 800,
      yearsOfExperience: 18,
      show: ['nl', 'es', 'it', 'fr'],
    },
    {
      name: {
        de: 'Dr. Gökay Bilgin',
        other: 'Dr. Gökay Bilgin, MD',
      },
      speciality: 'hairTransplant',
      slug: 'dr-gokay-bilgin-md',
      location: `Smile Hair Clinic At Moodist Hospital, Istanbul, ${t(
        'countries.turkey',
      )}`,
      image: `${IMGS_PATH.CONTENTFUL}/6CXawnbNnba6Cf0tb2H7AD/97ef4f55649866feb045188ea702955a/DR_G__KAY_B__LG__N_copy.jpg`,
      qunoscore: 9.7,
      treatmentsPerformedLastYear: 100000,
      yearsOfExperience: 15,
      show: [],
    },
    {
      name: {
        de: 'Dr. Mert Yuce',
        other: 'Dr. Mert Yuce, DDS, PhD',
      },
      speciality: 'dentistry',
      slug: 'dr-mert-yuce-dds-phd',
      location: `DENTAGLOBAL, Izmir, ${t('countries.turkey')}`,
      image: `${IMGS_PATH.CONTENTFUL}/7bu4qHoDOzQBbtnGGaTpCd/5e2820b51824fd64d41f9484c158ffda/DDS._PhD._Sukru_Mert_Yuce_cut.jpg`,
      qunoscore: 9.7,
      treatmentsPerformedLastYear: 7000,
      yearsOfExperience: 15,
      show: ['fr', 'it', 'es', 'nl'],
    },
    {
      name: {
        de: 'Dr. Emrah Cinik',
        other: 'Dr. Emrah Cinik, MD',
      },
      speciality: 'hairTransplant',
      slug: 'dr-emrah-cinik-md',
      location: `Dr. Cinik Hair Hospital, Istanbul, ${t('countries.turkey')}`,
      image: `${IMGS_PATH.CONTENTFUL}/6O1ixfeHqwuiuYUM7tzEjI/9477e398182e75de6428a6961a0f262b/Screenshot_2020-07-04_at_12.jpg`,
      qunoscore: 9.6,
      treatmentsPerformedLastYear: 500,
      yearsOfExperience: 19,
      show: ['nl', 'es', 'it'],
    },
    {
      name: {
        de: 'Dr. Fatih Ozturk',
        other: 'Dr. Fatih Ozturk, MD',
      },
      speciality: 'hairTransplant',
      slug: 'dr-fatih-ozturk-md',
      location: `Suzermed, Istanbul, ${t('countries.turkey')}`,
      image: `${IMGS_PATH.CONTENTFUL}/533x5tWzToLpGcgxvIx8jq/ca7a8c18d50100cfc7d849f994428b3e/file-1`,
      qunoscore: 8.8,
      objectPosition: 'object-center',
      treatmentsPerformedLastYear: 900,
      yearsOfExperience: 15,
      show: [],
    },
    {
      name: {
        de: 'Dr. Martina Karlo',
        other: 'Dr. Martina Karlo, DDS',
      },
      speciality: 'dentistry',
      slug: 'dr-martina-karlo-dds',
      location: `Dentelli, Split, ${t('countries.croatia')}`,
      image: `${IMGS_PATH.CONTENTFUL}/3hbHFEISllxmN0v51qUUcD/df908c82ceee6f08f713c8e54990bbb8/Dr-Martina-Karlo-Dentelli-O-Nama.jpeg`,
      qunoscore: 9.2,
      treatmentsPerformedLastYear: 1800,
      yearsOfExperience: 18,
      show: ['de', 'en', 'fr', 'it', 'es', 'nl'],
    },
    {
      name: {
        de: 'Dr. Hamid Aydin',
        other: 'Dr. Hamid Aydin, MD',
      },
      speciality: 'hairTransplant',
      slug: 'dr-hamid-aydin-md',
      location: `Liv Hospital Ulus Istanbul, Istanbul, ${t(
        'countries.turkey',
      )}`,
      image: `${IMGS_PATH.CONTENTFUL}/57AB4nLogwjWFZRH2A5ojd/5c0ad49299aafc0cfee2d773f0aac769/83-liv-hospital-ulus-istanbul-dr-hamid-aydin-md.jpg`,
      qunoscore: 8.6,
      treatmentsPerformedLastYear: 500,
      yearsOfExperience: 28,
      show: ['nl', 'es', 'it', 'fr'],
    },
    {
      name: {
        de: 'Dr. Maciej Borejsza',
        other: 'Dr. Maciej Borejsza, MD',
      },
      speciality: 'hairTransplant',
      slug: 'dr-maciej-borejsza-md',
      location: `Dr. Borejsza Clinic, Ruda Slaska, ${t('countries.poland')}`,
      image: `${IMGS_PATH.CONTENTFUL}/3250Er4Ca6deDMFA0aKnfG/276751784a8000640d8c1a8a7a96ac1f/maciej-borejsza`,
      qunoscore: 8.1,
      treatmentsPerformedLastYear: 100,
      yearsOfExperience: 9,
      show: ['nl', 'es', 'it', 'fr'],
    },
    {
      name: {
        de: 'Dr. Mehmet Erdogan',
        other: 'Dr. Mehmet Erdogan, MD',
      },
      speciality: 'hairTransplant',
      slug: 'dr-mehmet-erdogan-md',
      location: `Smile Hair Clinic, Istanbul, ${t('countries.turkey')}`,
      image: `${IMGS_PATH.CONTENTFUL}/2pMpxHJsDuOVxTXB4gf7wE/b09bcaeeb44467723bcb8e1ba519b53d/DR_MEHMET_ERDOGAN_.JPG`,
      qunoscore: 9.5,
      treatmentsPerformedLastYear: 750,
      yearsOfExperience: 10,
      show: ['de', 'en', 'nl', 'es', 'it', 'fr'],
    },
    {
      name: {
        de: 'Dr. Berk Angün',
        other: 'Dr. Berk Angün, MD',
      },
      speciality: 'fertility',
      slug: 'dr-berk-angun-md',
      location: `Dunya IVF Fertility Clinic, Kyrenia, ${t('countries.cyprus')}`,
      image: `${IMGS_PATH.CONTENTFUL}/3oZETV0F7msZe1Fwa1nOLo/85ef8985497e206f607aff70378365af/dr-bert-angu__n-large.png`,
      qunoscore: 8.7,
      treatmentsPerformedLastYear: 900,
      yearsOfExperience: 38,
      show: ['de', 'en'],
    },
    {
      name: {
        de: 'Prof. Dr. Rüdiger Heicappell',
        other: 'Prof. Dr. Rüdiger Heicappell, MD',
      },
      speciality: 'urology',
      slug: 'dr-ruediger-heicappell-md',
      location: `Berlin, ${t('countries.germany')}`,
      image: `${IMGS_PATH.CONTENTFUL}/5Vv1MlO8wWeuQLhDAUYSWi/6c115254e626bf8fadce8cc2c23a13bf/dr-ru__diger-heicapell-large.png`,
      qunoscore: 9.3,
      treatmentsPerformedLastYear: 250,
      yearsOfExperience: 38,
      show: ['de', 'en'],
    },
    {
      name: {
        de: 'Dr. Stefan Pfaffenberger',
        other: 'Dr. Stefan Pfaffenberger, MD',
      },
      speciality: 'cardiology',
      slug: 'dr-stefan-pfaffenberger-md',
      location: `Co-Ordination, ${t('city.vienna')}, ${t('countries.austria')}`,
      image: `${IMGS_PATH.CONTENTFUL}/6XKA0IbrzSTHb7tDZDPbiB/45e74c60904aae4f06010e1af0a40d9e/dr-stefan-pfaffenberger-large.png`,
      qunoscore: 9,
      treatmentsPerformedLastYear: 500,
      yearsOfExperience: 11,
      show: ['de', 'en'],
    },
  ];

  const happyCustomers = [
    {
      image: `${IMGS_PATH.PUBLIC}/home-reviews-1.jpg`,
      icon: 'PlasticSurgery',
      rating: 5,
      treatment: 'hairTransplant',
      name: 'Iwan',
      country: t('countries.unitedKingdom'),
      text: t('home.happyCustomers.review_1'),
      show: ['de', 'en', 'nl', 'es', 'it', 'fr'],
    },
    {
      image: `${IMGS_PATH.PUBLIC}/home-reviews-2.jpg`,
      icon: 'Tooth',
      rating: 5,
      treatment: 'veneers',
      name: 'Taniya',
      country: t('countries.unitedKingdom'),
      text: t('home.happyCustomers.review_2'),
      show: ['de', 'en', 'fr', 'it', 'es', 'nl'],
    },
    {
      image: `${IMGS_PATH.PUBLIC}/home-reviews-3.jpg`,
      icon: 'Balance',
      rating: 5,
      treatment: 'gastricBypass',
      name: 'Anja',
      country: t('countries.unitedKingdom'),
      text: t('home.happyCustomers.review_3'),
      show: ['de', 'en'],
    },
    {
      image: `${CLOUDINARY_UPLOAD_URL}/image/fred_q_before_after.png`,
      icon: 'PlasticSurgery',
      rating: 5,
      treatment: 'hairTransplant',
      name: 'Fred',
      country: t('countries.unitedKingdom'),
      text: t('home.happyCustomers.review_4'),
      show: [],
    },
    {
      image: `${IMGS_PATH.PUBLIC}/shane.png`,
      icon: 'PlasticSurgery',
      rating: 5,
      treatment: 'hairTransplant',
      name: 'Shane',
      country: t('countries.unitedKingdom'),
      text: t('home.happyCustomers.review_5'),
      show: ['nl', 'es', 'it', 'fr'],
    },
  ];

  const renderGetStartedButton = (): JSX.Element => {
    return (
      <ExternalFunnelButton
        funnelId={FunnelId.CONTACT_FORM}
        renderButton={(openFunnel) => {
          return (
            <Button
              className="mb-6"
              fullWidth="mobile"
              shadow={false}
              title={t('home.getStarted')}
              onClick={() => {
                trackEvent({
                  category: 'CTA',
                  action: 'CTA|Click',
                  label: 'CTA|Home|PopularTreatments|GetStarted',
                });

                openFunnel();
              }}
            >
              {t('home.getStarted')}
            </Button>
          );
        }}
      />
    );
  };

  return (
    <Layout
      title={t('home.metaTitle')}
      metaDescription={t('home.metaDescription')}
      alternateSlugs={alternateSlugs}
      footer={footer}
      showUfdBanner={false}
      brand={brand}
    >
      <StructuredData reviewStats={reviewStats} localizedUrl />
      <Hero />
      <TrustBanner />
      <PreviouslyFeaturedOn />
      {config.homepage.search.includes(locale) && <SearchSection />}
      {['nl', 'es', 'it', 'fr'].includes(locale) && (
        <>
          <div className="max-w-screen-xl mx-auto px-5 md:px-4">
            <h2 className="mb-4 md:mb-8 px-4 lg:px-0">
              {t('home.popularTreatments')}
            </h2>
            <Slider type="popularTreatments" items={popularTreatments} />
            <div className="flex-col-reverse flex md:flex-row justify-between px-4 lg:px-0">
              {renderGetStartedButton()}
            </div>
          </div>
          <hr className="mt-6 md:hidden" />
        </>
      )}
      <div className="text-left md:text-center text-navy px-5 lg:px-0">
        <div className="max-w-screen-md mx-auto md:px-4 py-8 md:py-12">
          <p className="font-netto text-3_5xl md:text-4_1xl mb-4">
            {t('home.theQunomedicalDifference')}
          </p>
          <p className="text-base md:text-lg">
            {t('home.theQunomedicalDifference.text')}
          </p>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto px-5 md:px-4 pb-8 md:pb-12">
        <div className="bg-secondary-medium pb-6 md:pb-12 px-6 md:px-12">
          <h2 className="md:text-center text-3_5xl font-netto mb-8 text-navy">
            {t('home.howCanWeHelp')}
          </h2>
          <div className="md:grid grid-cols-3 gap-8">
            <div className="mb-10 md:mb-0">
              <div className="inline-flex mr-2 bg-white rounded-full mb-6 justify-center items-center w-16 h-16">
                <Icon
                  type="VerifiedAlternate"
                  color={tailwind.theme.backgroundColor.navy}
                />
              </div>
              <p className="font-bold text-xl mb-4 text-navy ">
                {t('home.realPatientsRealReviews')}
              </p>
              <p>{t('home.realPatientsRealReviews.text')}</p>
            </div>
            <div className="mb-10 md:mb-0">
              <div className="inline-flex mr-2 bg-white rounded-full mb-6 justify-center items-center w-16 h-16">
                <Icon
                  type="Stethoscope"
                  color={tailwind.theme.backgroundColor.navy}
                />
              </div>
              <p className="font-bold text-xl mb-4 text-navy">
                {t('home.rightDoctorAndLocation')}
              </p>
              <p>{t('home.rightDoctorAndLocation.text')}</p>
            </div>
            <div className="mb-10 md:mb-0">
              <div className="inline-flex mr-2 bg-white rounded-full mb-6 justify-center items-center w-16 h-16">
                <Icon
                  type="Schedule"
                  color={tailwind.theme.backgroundColor.navy}
                />
              </div>
              <p className="font-bold text-xl mb-4 text-navy">
                {t('home.patientServiceAccess')}
              </p>
              <p>{t('home.patientServiceAccess.text')}</p>
            </div>
          </div>
        </div>
        <div className="bg-navy pb-12 px-6 md:px-12 mb-12 md:mb-14 rounded-bl-2xl">
          <h2 className="md:text-center text-3_5xl font-netto text-grandis mb-8 hyphenate">
            {t('home.ourPromiseToYou')}
          </h2>
          <div className="md:grid grid-cols-2 lg:grid-cols-4 gap-8 text-white">
            <div className="mb-10 md:mb-0">
              <Icon
                className="inline-flex mr-2 mb-4"
                type="Free"
                color={tailwind.theme.backgroundColor.secondary}
              />
              <p className="font-bold text-xl mb-5">
                {t('home.freeTransparent')}
              </p>
              <p>{t('home.freeTransparent.text')}</p>
            </div>
            <div className="mb-10 md:mb-0">
              <Icon
                className="inline-flex mr-2 mb-4"
                type="Stethoscope"
                color={tailwind.theme.backgroundColor.secondary}
              />
              <p className="font-bold text-xl mb-5">
                {t('home.worldRenowedDoctors')}
              </p>
              <p>{t('home.worldRenowedDoctors.text')}</p>
            </div>
            <div className="mb-10 md:mb-0">
              <Icon
                className="inline-flex mr-2 mb-4"
                type="SatisfactionGuarantee"
                color={tailwind.theme.backgroundColor.secondary}
              />
              <p className="font-bold text-xl mb-5">
                {t('home.satisfactionGuarantee')}
              </p>
              <p>{t('home.satisfactionGuarantee.text')}</p>
            </div>
            <div className="mb-10 md:mb-0">
              <Icon
                className="inline-flex mr-2 mb-4"
                type="ServiceFeePriceTag"
                color={tailwind.theme.backgroundColor.secondary}
              />
              <p className="font-bold text-xl mb-5">{t('home.priceMatch')}</p>
              <p>{t('home.priceMatch.text')}</p>
            </div>
          </div>
          <div className="text-center md:text-right mt-6">
            <Link href={`/${locale}/${t('header.about.link')}`}>
              <a className="underline text-white" title={t('home.findOutMore')}>
                <span className="flex items-center justify-center md:justify-end p-1">
                  <span className="mr-2">{t('home.findOutMore')}</span>
                  <span className="rounded-full border-2 border-white p-1">
                    <Icon
                      type="Angle"
                      className="w-2 h-2"
                      color={tailwind.theme.backgroundColor.white}
                    />
                  </span>
                </span>
              </a>
            </Link>
          </div>
        </div>
        {config.homepage.specialitiesOverview.includes(locale) && (
          <>
            {['de', 'en'].includes(locale) && (
              <div className="max-w-screen-xl mx-auto mb-2">
                <h2>{t('home.popularTreatments')}</h2>
                <div className="mt-3">
                  <Slider type="popularTreatments" items={popularTreatments} />
                </div>
                <div className="flex-col-reverse flex md:flex-row justify-between">
                  {renderGetStartedButton()}
                </div>
              </div>
            )}
            <div className="md:mx-4 lg:mx-0">
              <h2 className="mb-4 text-center md:text-left">
                {t('home.weOfferDiverseTreatmentsForAllHealthConcerns')}
              </h2>
              <SpecialitiesOverview />
              <div className="md:hidden">
                <ExternalFunnelButton
                  funnelId={FunnelId.CONTACT_FORM}
                  renderButton={(openFunnel) => {
                    return (
                      <Button
                        fullWidth
                        shadow={false}
                        className="my-6"
                        onClick={openFunnel}
                      >
                        {t('footer.contactUs')}
                      </Button>
                    );
                  }}
                />
              </div>
            </div>
          </>
        )}
        <hr className="my-6 md:hidden" />
        <div className="lg:grid lg:grid-cols-3 gap-8 md:mt-24">
          <div>
            <h2 className="md:mx-4 lg:mx-0 pt-0">{t('home.popularDoctors')}</h2>
            <p className="text-lg mb-6 md:mx-4 lg:mx-0">
              {t('home.popularDoctors.text')}
            </p>
          </div>
          <div className="col-span-2 -ml-3 -mr-3 md:mx-0">
            <Slider
              type="popularDoctors"
              items={popularDoctors}
              sliderItemClasses="mx-1 md:mx-2"
              smallScreenViewPorts="767px"
            />
          </div>
        </div>
      </div>
      <hr className="lg:hidden" />
      <div className="bg-secondary-bright">
        <div className="max-w-screen-xl mx-auto px-5 lg:px-4 py-12 lg:pt-20">
          <div className="lg:grid lg:grid-cols-3 gap-8">
            <div>
              <h2 className="md:mx-4 lg:mx-0 pt-0">
                {t('home.ourCustomersAreHappy')}
              </h2>
              <p className="text-lg mb-6 md:mx-4 lg:mx-0">
                {t('home.ourCustomersAreHappy.text')}
              </p>
              {'en' === locale && (
                <Link href={`/${locale}/reviews`}>
                  <a
                    title={t('home.seeReviews')}
                    className="hidden lg:inline-block font-netto py-2 px-6 bg-secondary rounded hover:bg-grandis transition-color duration-300 ease-in-out text-siam"
                    onClick={() =>
                      trackEvent({
                        category: 'CTA',
                        action: 'CTA|Click',
                        label: 'CTA|Home|HappyPatients|SeeReviews',
                      })
                    }
                  >
                    {t('home.seeReviews')}
                  </a>
                </Link>
              )}
            </div>
            <div className="col-span-2 -ml-3 -mr-3 md:mx-0">
              <Slider type="happyCustomers" items={happyCustomers} />
            </div>
          </div>
          {'en' === locale && (
            <div className="text-center">
              <Link href={`/${locale}/reviews`}>
                <a
                  title={t('home.seeReviews')}
                  className="lg:hidden font-netto"
                  onClick={() =>
                    trackEvent({
                      category: 'CTA',
                      action: 'CTA|Click',
                      label: 'CTA|Home|HappyPatients|SeeReviews',
                    })
                  }
                >
                  {t('home.seeReviews')}
                </a>
              </Link>
            </div>
          )}
          <hr className="mb-6 md:mb-20 mt-12" />
          <div>
            <TrustBox />
          </div>
          <Link href="https://www.trustpilot.com/review/www.qunomedical.com">
            <a
              title="See Reviews"
              className="text-center md:hidden underline block"
              onClick={() =>
                trackEvent({
                  category: 'CTA',
                  action: 'CTA|Click',
                  label: 'CTA|Home|HappyPatients|SeeReviews',
                })
              }
            >
              {t('seeAllReviews')}
            </a>
          </Link>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto px-5 md:px-4 pt-10 pb-5 md:py-20 mb-16">
        <div className="grid md:grid-cols-2 gap-12 xl:gap-32">
          <div>
            <p className="font-bold text-navy uppercase mb-6 md:mb-12">
              {t('home.provenSuccess.medicalTitle')}
            </p>
            <div className="lg:grid grid-cols-1 gap-8 mb-1 xl:h-24">
              <div className="flex">
                <div className="shrink-0">
                  <Image
                    className="w-20 object-contain"
                    src={`${IMGS_PATH.PUBLIC}/imtj_new.png`}
                    alt="IMTJ"
                    width={80}
                    height={107}
                  />
                </div>
                <p className="ml-4">{t('home.awardedByIMTJ')}</p>
              </div>
            </div>
          </div>
          <div>
            <p className="font-bold text-navy uppercase mb-4 md:mb-12">
              {t('certifiedOnlineSecurity')}
            </p>
            <p>{t('home.weAreGDPRCompliant')}</p>
            <div className="flex flex-wrap xs:block sm:flex justify-around md:justify-between mb-1 lg:h-24 items-center mt-4 lg:mt-0">
              <div className="mb-2 lg:mb-0 shrink-0">
                <Image
                  className="object-contain w-24 lg:w-32"
                  src={`${IMGS_PATH.PUBLIC}/norton-internet-security.jpg`}
                  alt="Norton Internet Security"
                  width={128}
                  height={70}
                />
              </div>
              <div className="shrink-0">
                <Image
                  className="object-contain w-28 lg:w-40"
                  src={`${IMGS_PATH.PUBLIC}/trust-lock-badge.png`}
                  alt="TrustLock"
                  width={160}
                  height={60}
                />
              </div>
              <div className="shrink-0">
                <Image
                  className="object-contain w-28 lg:w-32 mt-2 md:mt-0"
                  src={`${IMGS_PATH.PUBLIC}/gdpr-ready.jpg`}
                  alt="GDPR Ready"
                  width={128}
                  height={55}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetInTouch />
      <div className="max-w-screen-xl mx-auto px-3 lg:px-4 py-12 lg:py-20">
        <div className="lg:hidden uppercase font-bold text-navy">
          {t('backedByTopInvestors')}
        </div>
        <div className="grid grid-cols-2 md:flex items-center justify-between">
          <div className="hidden lg:block uppercase font-bold text-navy text-center">
            {t('backedByTopInvestors')}
          </div>
          {INVESTORS.map((investor, i) => (
            <div key={i} className="mx-auto my-8 lg:my-0">
              <Image
                src={`${IMGS_PATH.PUBLIC}/${investor.src}`}
                alt={investor.alt}
                width={investor.width}
                height={investor.height}
              />
            </div>
          ))}
        </div>
      </div>
      <ConversionBar />
    </Layout>
  );
};

export const getStaticProps: GetStaticProps<
  Props,
  { locale: string }
> = async ({ params }) => {
  const { locale } = params || {};
  const brand = await getBrand(process.env.BRAND_ID || null, locale);

  // -- Create alternate slugs manually to inject hreflang tags
  const alternateSlugs = config.locales.map((entry: ConfigLocale) => ({
    locale: entry.main,
    slug: ``,
  }));

  const reviews = await getAllReviewsForCount();
  const reviewStats = {
    avg_rating: getAverageRating(reviews),
    total: reviews?.length,
  };

  return {
    props: {
      locale,
      brand,
      alternateSlugs,
      footer: getMainFooter(locale),
      reviewStats,
    },
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  if (skipServerSideBuild()) {
    return {
      paths: [],
      fallback: STATIC_PATH_FALLBACK,
    };
  }

  const paths = config.locales.map((locale: ConfigLocale) => ({
    params: { locale: locale.main },
  }));

  return {
    paths,
    fallback: false,
  };
};

export default Home;
