import clsx from 'clsx';
import { Direction } from 'types/Utilities';
import Icon from 'components/ui/Icon';

type Props = {
  color?: string;
  direction: 'left' | 'right';
  onClick?: () => void;
  className?: string;
  style?: Record<string, unknown>;
};

const CustomSliderArrow = ({
  color = '#5f6258',
  direction,
  onClick,
  className,
  style,
}: Props): JSX.Element => (
  <Icon
    className={clsx(
      'md:block shrink-0 absolute outline-none cursor-pointer transition-all duration-500 z-10',
      className,
    )}
    type="Arrow"
    direction={direction === 'left' ? Direction.LEFT : Direction.RIGHT}
    color={color}
    onClick={() => {
      onClick?.();
    }}
    style={style}
  />
);

export default CustomSliderArrow;
