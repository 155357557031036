import Cookie from 'js-cookie';
import config from 'config';

export type UseCountry = (useFallback?: boolean) => { country: string };

export const useCountry: UseCountry = (useFallback = true) => {
  const country = Cookie.get('userJourneyCountry') || config.defaults.country;

  if (
    useFallback &&
    !config.countries[country as keyof typeof config.countries]
  ) {
    return { country: config.defaults.country };
  }

  return { country };
};
