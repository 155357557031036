import { useEffect } from 'react';

/**
 * Hook to prevent vertical scroll while swiping in the carousel
 *
 * @param containerRef - the container to control scrolling
 */
export const useVerticalSwipePreventer = (
  containerRef: React.MutableRefObject<HTMLDivElement | null>,
): void => {
  let initialX: number | null = null;
  let initialY: number | null = null;

  const startTouch = (event: TouchEvent): void => {
    initialX = event.touches[0].clientX;
    initialY = event.touches[0].clientY;
  };

  const moveTouch = (event: TouchEvent): void => {
    // threshold
    const minValue = 5;
    if (initialX === null || initialY === null) {
      return;
    }

    const currentX = event.touches[0].clientX;
    const diffX = initialX - currentX;

    if (Math.abs(diffX) > minValue) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const container =
      containerRef && containerRef.current ? containerRef.current : null;

    if (container !== null) {
      container.addEventListener('touchstart', startTouch);
      container.addEventListener('touchmove', moveTouch, { passive: false });
    }

    return () => {
      if (container !== null) {
        container.removeEventListener('touchstart', startTouch);
        container.removeEventListener('touchmove', moveTouch);
      }
    };
  }, [containerRef]);
};
